import React from "react";
import PropTypes from "prop-types";
//@material-ui/core components
import { makeStyles } from "@material-ui/core";
//core components
import DOMpurify from "dompurify";
import GridItem from "components/Grid/GridItem.js";

const useStyles = makeStyles({
  red: { backgroundColor: "red" },
  container: { margin: "32px 0px 16px 0px" },
  videoFrame: {
    textAlign: "left",
    margin: "32px 0px 12px 0px",
  },
  textContainer: {
    margin: "64px 0px",
  },
  title: {
    fontWeight: "bold",
    marginBottom: "0px",
  },
  subtitle: {
    margin: "0px",
  },
});

export default function TutorialCard({ title, text, video }) {
  const classes = useStyles();
  const sanitizedVideoUrl = DOMpurify.sanitize(
      `https://www.youtube.com/embed/${video.slice(32)}`,
      {
          ALLOWED_TAGS: ["iframe"],
          ALLOWED_ATTR: ["width", "height", "src", "allowfullscreen", "title"],
      }
  );
  const videoHtml = (
      <iframe
          width="90%"
          height="360px"
          src={sanitizedVideoUrl}
          allowFullScreen
          title="Embedded youtube"
      ></iframe>
  );

  const sanitizedVideo = DOMpurify.sanitize(videoHtml);

  const hasVideo = video ? true : false;

  return (
      <GridItem container extraClass={classes.container}>
          <GridItem container xs={12} md={6} extraClass={classes.textContainer}>
              <GridItem xs={12}>
                  <h4 className={classes.title}>{title}</h4>
              </GridItem>
              <GridItem xs={12}>
                  <h5 className={classes.subtitle}>{text}</h5>
              </GridItem>
          </GridItem>
          <GridItem xs={12} md={6} extraClass={classes.videoFrame}>
              {hasVideo ? (
                  <div dangerouslySetInnerHTML={{ __html: sanitizedVideo }} />
              ) : (
                  <h3>Sem vídeo</h3>
              )}
          </GridItem>
      </GridItem>
  );
}

TutorialCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  video: PropTypes.string,
};
