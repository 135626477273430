import React, { useState } from "react";
import api from "services/api";
import PropTypes from "prop-types";
//@material-ui/core components
import { makeStyles } from "@material-ui/core";
//core components
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import DOMpurify from "dompurify";

import { primaryColor } from "assets/jss/material-dashboard-react";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
const useStyles = makeStyles({
  ...styles,
  title: {
    fontWeight: "bolder",
    color: primaryColor[0],
  },
  videoFrame: {
    margin: "16px 0px",
  },
  button: {
    margin: "16px 0px",
  },
});

export default function NewTutorial({ name, text, link }) {
  const classes = useStyles();

  const [title, setTitle] = useState(name ? name : "");
  const [video, setVideo] = useState(link ? link : "");
  const [description, setDescription] = useState(text);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  function videoCheck() {
    if (video.length > 32) {
      return true;
    } else {
      return false;
    }
  }

  function isValid() {
    if (videoCheck() && description && title) {
      setError(false);
      setMessage("");
      return true;
    } else {
      setError(true);
      setMessage("Preencha todos os campos para publicar o tutorial");
      return false;
    }
  }

  async function handleCreateTutorial() {
    if (isValid()) {
      try {
        setLoading(true);

        await api.post(`/tutorials`, {
          titulo: title,
          descricao: description,
          midia: video,
        });
      } catch (error) {
        setError(true);
        setMessage(
          "Ocorreu um erro na hora de publicar o tutorial. Tente novamente"
        );
        console.log(error.response.data.error);
      } finally {
        setLoading(false);
        setMessage("Tutorial publicado com sucesso");
      }
    }
  }

  const sanitizedVideoUrl = DOMpurify.sanitize(
      `https://www.youtube.com/embed/${video.slice(32)}`,
      {
          ALLOWED_TAGS: ["iframe"],
          ALLOWED_ATTR: ["width", "height", "src", "allowfullscreen", "title"],
      }
  );

  const videoHtml = (
      <iframe
          width="90%"
          height="360px"
          src={sanitizedVideoUrl}
          allowFullScreen
          title="Embedded youtube"
      ></iframe>
  );

  const sanitizedVideo = DOMpurify.sanitize(videoHtml);


  return (
      <GridItem container xs={12} md={11}>
          <GridItem xs={12}>
              <h3 className={classes.title}>Nova entrada</h3>
              <h5
                  style={{
                      fontWeight: "bolder",
                      color: error ? "red" : "green",
                  }}
              >
                  {message}
              </h5>
              <CustomInput
                  labelText="Título"
                  id="title"
                  formControlProps={{
                      fullWidth: true,
                  }}
                  inputProps={{
                      value: title,
                      onChange: (e) => setTitle(e.target.value),
                  }}
              />
          </GridItem>
          <GridItem xs={12}>
              <CustomInput
                  labelText="Video"
                  id="video"
                  formControlProps={{
                      fullWidth: true,
                  }}
                  inputProps={{
                      value: video,
                      onChange: (e) => setVideo(e.target.value),
                  }}
              />
          </GridItem>
          <GridItem xs={12}>
              <CustomInput
                  labelText="Descrição"
                  id="description"
                  formControlProps={{
                      fullWidth: true,
                  }}
                  inputProps={{
                      multiline: true,
                      rows: 3,
                      value: description,
                      onChange: (e) => setDescription(e.target.value),
                  }}
              />
          </GridItem>
          <GridItem container>
              <GridItem xs={12} sm={3}>
                  <h4>Video:</h4>
              </GridItem>
              <GridItem xs={12} sm={8} md={6}>
                  {videoCheck() ? (
                      <div
                          dangerouslySetInnerHTML={{ __html: sanitizedVideo }}
                      />
                  ) : (
                      <h4>Vídeo não encontrado</h4>
                  )}
              </GridItem>
          </GridItem>
          <GridItem container justify="flex-end">
              <GridItem xs={12} sm={3} md={2}>
                  <Button
                      loading={loading}
                      disabled={loading}
                      color="primary"
                      className={classes.button}
                      onClick={handleCreateTutorial}
                  >
                      Publicar
                  </Button>
              </GridItem>
          </GridItem>
      </GridItem>
  );
}

NewTutorial.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
};
